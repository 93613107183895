import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TilPage = ({ data }) => {
  const tils = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="TILs - Things I've Learned" />
      <div className="mb-12">
        <h1 className="text-4xl font-bold mb-4 text-gray-900">TILs</h1>
        <p className=" text-gray-600">
          Things I've Learned, inspired by{" "}
          <a 
            href="https://til.simonwillison.net/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            Simon Willison's TIL
          </a>
        </p>
      </div>
      <div className="grid grid-cols-1 gap-5">
        {tils.map(til => (
          <div
            key={til.id}
            className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-gray-200 transition-all duration-300 hover:shadow-lg hover:border-gray-400"
          >
            <div className="p-6">
              <Link
                to={til.fields.slug}
                className="text-gray-900 hover:text-black"
              >
                <h3 className="text-2xl font-bold mb-2">
                  {til.frontmatter.title}
                </h3>
              </Link>
              <small className="text-gray-600 block mb-4">
                {til.frontmatter.date}
              </small>
              {til.frontmatter.tags && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {til.frontmatter.tags.map(tag => (
                    <Link
                      key={tag}
                      to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 hover:bg-gray-300"
                    >
                      #{tag}
                    </Link>
                  ))}
                </div>
              )}
              <p className="text-gray-700 mb-4">{til.excerpt}</p>
              <Link
                to={til.fields.slug}
                className="inline-block bg-gray-800 text-white py-2 px-4 rounded hover:bg-black transition-colors duration-300"
              >
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default TilPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/til/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 100
    ) {
      nodes {
        excerpt(pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
        id
      }
    }
  }
` 